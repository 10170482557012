<template>
<div id="PassportSignin">
    <el-form :model="signinForm" class="signinForm shadow">
        <el-form-item class="form-item">
            <el-input clearable maxlength="20" @input="inputItemVerify" v-model="signinForm.mobile" placeholder="登录账号">
                <template #prepend><i class="iconfont icon-usercenter" /></template>
            </el-input>
        </el-form-item>
        <el-form-item class="form-item">
            <el-input clearable ref="password" @input="inputItemVerify" maxlength="20" show-password v-model="signinForm.password" placeholder="登录密码">
                <template #prepend><i class="iconfont icon-password" /></template>
            </el-input>
        </el-form-item>
        <el-form-item class="form-item">
            <el-checkbox v-model="saveSigninInfo" @change="eventSaveSigninInfo" size="large">记住登录信息&nbsp;<span style="color: red">(请勿在公共电脑上使用)</span></el-checkbox>
        </el-form-item>
        <el-form-item class="form-item">
            <el-button :disabled="buttonSigninLocked" :loading="buttonSigninLoading" class="full-width" type="primary" @click="buttonSignin">{{buttonSigninLoading ? '正在认证账户信息，请稍后......' : '登录'}}</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
let signinInfo = null
export default {
    name: "PassportSignin",
    data() {
        return {
            saveSigninInfo: false,
            buttonSigninLoading: false,
            buttonSigninText: '登录',
            buttonSigninLocked: true,
            signinForm: {
                mobile: '',
                password: '',
            },
        };
    },
    created() {
        signinInfo = window.localStorage.getItem('signinInfo')
        if (signinInfo) {
            this.saveSigninInfo = true
            let decryptedData = JSON.parse(this.$sm2.decrypted(signinInfo, this.$store.state.clientPrivateKey))
            this.signinForm.mobile = decryptedData.mobile
            this.$nextTick(function () {
                this.$refs.password.ref.placeholder = (decryptedData.password.length > 0) ? '密码已保存' : '登录密码'
            })
            this.buttonSigninLocked = (decryptedData.mobile.length == 0 || decryptedData.password.length == 0) ? true : false
        }
    },
    methods: {
        inputItemVerify() {
            this.buttonSigninLocked = (this.signinForm.mobile.length == 0 || this.signinForm.password.length == 0) ? true : false
        },
        eventSaveSigninInfo(state) {
            if (state) {
                signinInfo = this.$sm2.encrypted(JSON.stringify(this.signinForm), this.$store.state.clientPublicKey)
                window.localStorage.setItem('signinInfo', signinInfo)
            } else {
                window.localStorage.removeItem('signinInfo')
            }
        },
        buttonSignin() {
            let mobile = ''
            let password = ''
            if (signinInfo) {
                let decryptedData = JSON.parse(this.$sm2.decrypted(signinInfo, this.$store.state.clientPrivateKey))
                mobile = decryptedData.mobile
                password = (this.signinForm.password) ? this.signinForm.password : decryptedData.password
            } else {
                mobile = this.signinForm.mobile
                password = this.signinForm.password
            }
            if (!mobile) {
                this.$message('请输入登录账号')
            } else if (!password) {
                this.$message('请输入登录密码')
            } else {
                this.buttonSigninLoading = true
                let data = {
                    mobile: mobile,
                    password: password
                }
                let terminalSignature = this.$signature.signature(data, this.$store.state.clientPrivateKey)
                this.$http.post('/passport/manage/signin', data, {
                    'terminal-signature': terminalSignature
                }, (response) => {
                    let signatureVerify = this.$signature.verify(response.data, this.$store.state.serverPublicKey)
                    if (signatureVerify) {
                        this.$message.success('登录成功')
                        window.localStorage.removeItem('viewId')
                        window.localStorage.removeItem('viewBox')
                        let terminalToken = response.data.data.terminalToken;
                        this.$store.commit('updateTerminalToken', terminalToken);
                        this.$router.replace('/console/panel');
                    } else {
                        this.$toast('验证签名失败')
                    }
                }, () => {
                    this.buttonSigninLoading = false
                })
            }

        }
    }
};
</script>

<style>
body {
    background-image: url("../../assets/images/bg.jpg");
    background-position: center center;
    /* 背景图不平铺 */
    background-repeat: no-repeat;
    /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
    background-attachment: fixed;
    /* 让背景图基于容器大小伸缩 */
    background-size: cover;
    /** Firefox **/
    -webkit-background-size: cover;
    /** Safari 和 Chrome **/
    -o-background-size: cover;
    /** Opera **/
}
</style>

<style lang="less" scoped>
.signinForm {
    background: #f4f4f4;
    width: 500px;
    padding: 50px;
    margin: 200px 0 0 calc(~"100% - 650px");
}

.form-item {
    margin-bottom: 20px;
}

.full-width {
    width: 100%;
}

.shadow {
    position: relative;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3),
        0px 0px 20px rgba(0, 0, 0, 0.1) inset;
}

.shadow::before,
.shadow::after {
    content: "";
    position: absolute;
    z-index: -1;
}

.shadow::before,
.shadow::after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 15px;
    left: 10px;
    width: 50%;
    height: 20%;
}

.shadow::before,
.shadow::after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 15px;
    left: 10px;
    width: 50%;
    height: 20%;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
    transform: rotate(-3deg);
}

.shadow::after {
    right: 10px;
    left: auto;
    transform: rotate(3deg);
}
</style>
